import PowerSupply from "../assets/images/power-supply.svg";
import Ram from "../assets/images/ram.svg";
import GraphicCard from "../assets/images/graphics-card.svg";
import Storage from "../assets/images/storage.svg";
import MotherBoard from "../assets/images/mother-board.svg";
import ComputerCase from "../assets/images/computer-case.svg";
import Accessories from "../assets/images/accessories.svg";
import Processors from "../assets/images/processors.svg";
import Laptops from "../assets/images/laptops.svg";
import Nvme from "../assets/images/nvme.svg";
import PcCooling from "../assets/images/fan-cooler.svg";

const categories = [
    { name: "Mother Board", src: MotherBoard, slug: "motherboard"},
    { name: "Processors", src: Processors, slug: "cpu" },
    { name: "RAM", src: Ram , slug: "ram"},
    { name: "NVME - M2", src: Nvme , slug: "nvme-m.2" },
    { name: "Storage", src: Storage, slug: "storage" },
    { name: "Graphic Cards", src: GraphicCard, slug: "graphic-cards" },
    { name: "Power Supply", src: PowerSupply , slug: "power-supply"},
    { name: "Computer Case", src: ComputerCase, slug: "computer-case"},
    { name: "PC Cooling", src: PcCooling, slug: "pc-cooling"},
    { name: "Accessories", src: Accessories , slug: "accessories" },
    { name: "Laptops", src: Laptops , slug: "laptops" },

];

export default categories;
