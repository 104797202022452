import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Header from "../components/Header";
import Nav from "../components/Nav";
import Loader from "../components/Loader";
import api from "../statics/api.js";
import StarGold from "../assets/icons/star-gold.svg";
import StarGray from "../assets/icons/star-gray.svg";
import WhiteHeart from "../assets/icons/heart-white.svg";

import UserIcon from "../assets/images/animoji.png";
import Overlay from "../components/Overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCookie } from "../App.js";
import Cart from "../components/cart.compontent.jsx";
import cart, { add_to_cart, set_cart } from "../reducers/cart.js";
import { useDispatch } from "react-redux";
import { set_username } from "../reducers/username.js";
import SearchInput from "../components/search-input.component.jsx";
import MobileMenu from "../components/menu.component.jsx";
import Footer from "../components/footer.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";

function ProductPage() {
  const { query } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [highQualityImages, setHighQualityImages] = useState([]);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const [currentColorIndex, setCurrentColorIndex] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [currentDiscount, setCurrentDiscount] = useState({});
  const [minPurchaseQty, setMinPurchaseQty] = useState(0);
  const [maxPurchaseQty, setMaxPurchaseQty] = useState(0);
  const [purchaseQty, setPurchaseQty] = useState(0);
  const [stock, setStock] = useState(0);
  const [bar, setBar] = useState(0);
  const goldStars = [];
  const grayStars = [];
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Populate goldStars and grayStars arrays
  for (let i = 0; i < Math.floor(data.avg_rating); i++) {
    goldStars.push(<img key={i} src={StarGold} alt="gold star" />);
  }
  // If the average rating is not an integer, add one gray star
  if (data.avg_rating % 1 !== 0) {
    grayStars.push(<img key="gray" src={StarGray} alt="gray star" />);
  }
  // Fll the remaining gray stars up to 4
  const remainingGrayStars = 5 - Math.ceil(data.avg_rating);
  for (let i = 0; i < remainingGrayStars; i++) {
    grayStars.push(
      <img
        key={i + Math.floor(data.avg_rating)}
        src={StarGray}
        alt="gray star"
      />
    );
  }

  const handleAddToCart = async () => {
    setLoading(true);
    let postData = new FormData();
    const session = getCookie("session");
    if (!session) {
      let cart = localStorage.getItem("cart") || [];
      cart = JSON.parse(cart);
      let is_found = false;
      let index = 0;
      cart.map((ele, ind) => {
        if (ele.sku === data.sku) {
          if (data.colors) {
            if (ele.color === data.colors[currentColorIndex].hexcode) {
              is_found = true;
              index = ind;
            }
          } else {
            is_found = true;
            index = ind;
          }
        }
      });
      // product is already in the cart
      if (is_found) {
        let newElement = cart[index];
        if (newElement["qty"] + purchaseQty > data.max_purchase_qty) {
          toast.error(
            "Quantity is out of acceptable range, max quantity is " +
              data.max_purchase_qty +
              " piece/pieces"
          );
          setLoading(false);
          return;
        }
        newElement["qty"] = newElement["qty"] + purchaseQty;
        setLoading(false);
        localStorage.setItem("cart", JSON.stringify(cart));
        set_cart(dispatch(set_cart(cart)));
        toast.success("Added.");
        return;
      }

      cart.push({
        ...data,
        color: (data.colors && data.colors[currentColorIndex]?.hexcode) || "",
        qty: purchaseQty,
        img1: data.imgs_high_quality.split(",")[0],
        slug: query,
      });

      localStorage.setItem("cart", JSON.stringify(cart));
      set_cart(dispatch(set_cart(cart)));
      toast.success("Added.");
      setLoading(false);
      return;
    }

    postData.append("session", session);
    postData.append("product_sku", data.sku);
    if (data.colors) {
      postData.append("product_color", data.colors[currentColorIndex].hexcode);
    } else {
      postData.append("product_color", "none");
    }
    postData.append("product_qty", purchaseQty);

    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(`${api}add_to_cart.php`, {
          method: "POST",
          body: postData,
        });
        let responseData = await response.text();
        responseData = JSON.parse(responseData);
        setLoading(false);
        if (responseData.state) {
          if (response.res) {
            if (responseData.res.msg === "New session") {
              const expiryDate = new Date(responseData.exp);
              document.cookie = `session=${
                responseData.new_session
              }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
              localStorage.setItem("fullname", responseData.res.username);
              dispatch(set_username(localStorage.getItem("fullname")));
            }
          }
          add_to_cart(
            dispatch(
              add_to_cart({
                ...data,
                color:
                  (data.colors && data.colors[currentColorIndex]?.hexcode) ||
                  null,
                qty: purchaseQty,
                img1: data.imgs_high_quality.split(",")[0],
              })
            )
          );
        } else {
          toast.error(responseData.msg);
        }

        return;
      } catch (error) {
        console.log(error);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      return;
    }
  };

  const addToWishList = async () => {
    let postData = new FormData();
    const session = getCookie("session");

    if (!session) {
      toast.error("You must log in first.");
      return;
    }

    postData.append("session", session);
    postData.append("product_sku", data.sku);

    try {
      let response = await fetch(`${api}add_to_wishlist.php`, {
        method: "POST",
        body: postData,
      });

      let responseData = await response.json(); // Directly parse JSON

      if (responseData.state) {
        toast.success("Product added to wishlist successfully");
        if (responseData.res.msg && responseData.res.msg === "New session") {
          const expiryDate = new Date(responseData.exp);
          document.cookie = `session=${
            responseData.new_session
          }; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
          localStorage.setItem("fullname", responseData.res.username);
          dispatch(set_username(localStorage.getItem("fullname")));
        }
        return;
      } else {
        toast.error(responseData.msg);
        return;
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  const CalculateCurrentProductPrice = () => {
    if (data.discounts) {
      let isFound = false;
      data.discounts.forEach((ele) => {
        if (
          (data.colors &&
            ele["color"] === data.colors[currentColorIndex].hexcode) ||
          data["has_nocolors"]
        ) {
          if (ele["type"] === "percent") {
            const newPrice = data.price * (1 - ele.amount);
            setProductPrice(newPrice.toFixed(2));
            setCurrentDiscount(ele);
          } else {
            const newPrice = data.price - ele.amount;
            setProductPrice(newPrice.toFixed(2));
            setCurrentDiscount(ele);
          }
          isFound = true;
        }
      });
      if (!isFound) {
        setProductPrice(data.price);
      }
    }
  };
  const handleIncreasePuchaseQty = () => {
    if (maxPurchaseQty !== purchaseQty) {
      setPurchaseQty(purchaseQty + 1);
    } else {
    }
  };
  const handleDecreasePuchaseQty = () => {
    if (minPurchaseQty !== purchaseQty) {
      setPurchaseQty(purchaseQty - 1);
    }
  };
  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };
  const loadData = async () => {
    setLoading(true);
    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(`${api}product_details.php?slug=${query}`);
        let responseData = await response.text();
        responseData = JSON.parse(responseData);
        responseData["specifications"] = JSON.parse(
          responseData["specifications"]
        );
        setData(responseData);
        setHighQualityImages(responseData.imgs_high_quality.split(","));
        setProductPrice(responseData.price);
        setMinPurchaseQty(+responseData.min_purchase_qty);
        setMaxPurchaseQty(+responseData.max_purchase_qty);
        setPurchaseQty(+responseData.min_purchase_qty);
        setLoading(false);
        if (responseData.state === false) {
          return;
        }
        return;
      } catch (error) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  useEffect(() => {
    if (query === undefined) {
      navigate("/");
    }
    loadData();
  }, []);

  useEffect(() => {
    CalculateCurrentProductPrice();
  }, [currentColorIndex, data]);

  useEffect(() => {
    // execute on location change
    loadData();
  }, [location]);

  return (
    <>
      <Nav></Nav>
      <Header onSearchClick={handleSearchClick}></Header>
      <main className="mt-8 mx-4 md:mx-8">

        <div className="flex flex-col md:flex-row md:gap-6 md:align-top ">
          {/* Images */}
          <div className="flex flex-col gap-2 items-center">
            <div className="relative">
              <div className="absolute z-10 top-2 left-2 font-semibold text-white flex gap-1">
                {data.colors &&
                  data.colors[currentColorIndex] &&
                  data.colors[currentColorIndex].stock === 1 && (
                    <div className="font-inter p-1 rounded-[4px] bg-red">
                      Last Piece!{" "}
                    </div>
                  )}
                {data.is_new && (
                  <div className="font-inter p-1 rounded-[4px] save-gradient ">
                    New
                  </div>
                )}
                {data.is_comingsoon && (
                  <div className="font-inter p-1 rounded-[4px] save-gradient ">
                    Comming soon!
                  </div>
                )}
                {data.is_limited_offer && (
                  <div className="font-inter p-1 rounded-[4px] save-gradient ">
                    Limited Offer
                  </div>
                )}
                {data.stock && data.stock === 1 && (
                  <div className="font-inter p-1 rounded-[4px] bg-red">
                    Last Piece!
                  </div>
                )}
              </div>
              <img
                src={highQualityImages[currentImgIndex]}
                alt="img"
                className="w-full rounded-[4px] max-w-[500px]"
              />
            </div>
            <div className="flex gap-2">
              {highQualityImages.map((ele, index) => (
                <div
                  key={index}
                  className="w-14 relative border-gradient rounded-[2px] before:rounded-[2px] after:rounded-[2px]"
                  onClick={() => setCurrentImgIndex(index)}
                >
                  <img src={ele} alt="img" className="rounded-[2px]" />
                  {currentImgIndex !== index ? (
                    <div className="bg-black opacity-60 absolute inset-0"></div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          </div>
          {/* Details */}
          <div className="flex flex-col gap-1 mt-16 md:mt-0">
            <h2 className="text-base text-white font-['cairo'] m-0 md:text-2xl">
              {data.title}
            </h2>
            <div className="flex gap-1">
              {goldStars}
              {grayStars}
              <span className="text-bw-06">
                ({(data.reviews && data.reviews.length) || 0})
              </span>
            </div>

            <div className="flex items-center gap-2">
              <span className="text-gradient font-['poppins'] text-xl font-semibold">
                {productPrice} LE
              </span>
              {data.price !== productPrice && (
                <>
                  <span className="line-through text-base text-bw-04">
                    {data.price}
                  </span>
                  <div className="ml-4 text-white p-2 save-gradient text-xs rounded-[4px] flex items-center justify-center ">
                    <span className="font-['cairo'] text-xs">
                      {(currentDiscount && currentDiscount.type) === "percent"
                        ? `Save ${currentDiscount.amount * 100}%`
                        : `Save ${currentDiscount.value}`}
                    </span>
                  </div>
                </>
              )}
            </div>

            {/* Stock */}
            <div className="flex items-center gap-2">

              <span className="font-['cairo'] text-lg text-white font-bold">
                Stock:{" "}
              </span>
              
              {data.has_nocolors && data.stock !== undefined && (
                <>
                  {data.stock > 0 ? (
                    <span className="font-['cairo'] text-lg text-green">
                      In Stock
                    </span>
                  ) : data.stock === 0 ? (
                    <span className="font-['cairo'] text-lg text-red">
                      Out of Stock
                    </span>
                  ) : data.is_comingsoon && data.is_comingsoon === 1 ? (
                    <span className="font-['cairo'] text-lg text-blue">
                      Soon
                    </span>
                  ) : null}
                </>
              )}
              {!data.has_nocolors &&
                data.colors &&
                data.colors[currentColorIndex] &&
                data.colors[currentColorIndex].stock !== undefined && (
                  <>
                    {data.colors[currentColorIndex].stock > 0 ? (
                      <span className="font-['cairo'] text-lg text-[#5FC963]">
                        In Stock
                      </span>
                    ) : data.colors[currentColorIndex].stock === 0 ? (
                      <span className="font-['cairo'] text-lg text-red">
                        Out of Stock
                      </span>
                    ) : data.is_comingsoon && data.is_comingsoon === 1 ? (
                      <span className="font-['cairo'] text-lg text-blue">
                        Soon
                      </span>
                    ) : null}
                  </>
                )}
            </div>

            {/* Model */}
            <div className="flex items-center gap-2">
              <span className="font-['cairo'] text-lg text-white font-bold">
                Model:{" "}
              </span>
              <span className="font-['cairo'] text-lg text-white">
                {data.model}
              </span>
            </div>

            {/* Brand */}
            <div className="flex gap-2 flex-col">
              <div>
                <span className="font-['cairo'] text-lg text-white font-bold">
                  Brand:{" "}
                </span>
                <span className="font-['cairo'] text-lg text-white">
                  {data.brand_name}
                </span>
              </div>
              <div className="w-16 rounded-lg border-[1.5px] border-bw-03 flex items-center justify-center bg-bw-02 p-2 ">
                <img src={data.brand_img} alt="img" />
              </div>
            </div>

            {/* Colors */}
            {!data.has_nocolors && data.colors && (
              <div className="flex items-center gap-4">
                <span className="font-['cairo'] text-lg text-white font-bold">
                  Color:{" "}
                </span>
                <div className="flex gap-1">
                  {data.colors.map((ele, index) => (
                    <div
                      key={index}
                      className={`w-8 h-8 relative rounded-lg ${
                        currentColorIndex === index ? "border-gradient" : ""
                      }`}
                      style={{ backgroundColor: ele.hexcode }}
                      onClick={() => {
                        setCurrentColorIndex(index);
                        setPurchaseQty(minPurchaseQty);
                      }}
                    >
                      <div
                        className="absolute inset-0  rounded-lg"
                        style={{ backgroundColor: ele.hexcode }}
                      ></div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {/* Qty */}
            <div className="select-none flex items-center gap-4 my-2">
              <span className="font-['cairo'] text-lg text-white font-bold">
                Quantity:{" "}
              </span>
              <div className="flex gap-2">
                <div
                  className="w-10 h-10 bg-bw-02 border-[1.5px] border-bw-03 rounded-l-lg flex items-center justify-center cursor-pointer"
                  onClick={handleDecreasePuchaseQty}
                >
                  {purchaseQty === minPurchaseQty ? (
                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">
                      -
                    </span>
                  ) : (
                    <span className="text-gradient font-['kensmark-03'] text-2xl">
                      -
                    </span>
                  )}
                </div>

                <div className="w-10 h-10 text-white font-['kensmark-03'] bg-bw-02 border-[1.5px] border-bw-03 flex items-center justify-center">
                  {purchaseQty}
                </div>
                <div
                  className="w-10 h-10 bg-bw-02 border-[1.5px] border-bw-03 rounded-r-lg flex items-center justify-center cursor-pointer"
                  onClick={handleIncreasePuchaseQty}
                >
                  {purchaseQty === maxPurchaseQty ? (
                    <span className="text-bw-03 font-['kensmark-03'] text-2xl">
                      +
                    </span>
                  ) : (
                    <span className="text-gradient font-['kensmark-03'] text-2xl">
                      +
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Add to Cart & Buy Now */}
            <div className="rounded-lg select-none font-['kensmark-03'] gap-2 flex text-sm">
              {data.colors &&
              data.colors[currentColorIndex] &&
              typeof data.colors[currentColorIndex].stock !== "undefined" &&
              data.colors[currentColorIndex].stock !== 0 ? (
                <>
                  <div
                    className="py-2 border-[3px] border-bw-03  flex items-center justify-center rounded-lg text-white flex-1 cursor-pointer"
                    onClick={handleAddToCart}
                  >
                    ADD TO CART
                  </div>
                  {/* <div className="py-2 rounded-lg bg-bw-02 flex items-center justify-center flex-1 cursor-pointer ">
                      <span className="text-gradient">BUY NOW</span>
                    </div> */}
                </>
              ) : null}
              {data.stock && data.stock !== 0 ? (
                <>
                  <div
                    className="py-2 border-[3px]  border-bw-03 flex items-center justify-center rounded-lg text-white flex-1 cursor-pointer max-w-64"
                    onClick={handleAddToCart}
                  >
                    ADD TO CART
                  </div>
                  {/* <div className="py-2 rounded-lg bg-bw-02 flex items-center justify-center flex-1 cursor-pointer ">
                    <span className="text-gradient">BUY NOW</span>
                  </div> */}
                </>
              ) : null}
            </div>

            {/* Add to Wishlist */}
            <div className="flex gap-2 cursor-pointer" onClick={addToWishList}>
              <img src={WhiteHeart} alt="icon" />
              <span className="text-white font-['cairo'] select-none">
                Add to wishlist
              </span>
            </div>
          </div>
        </div>

        {/* Description & Specifications and Reviews */}
        <div className="flex bg-bw-02 rounded-lg gap-2 p-1 h-10 text-xs mt-8 justify-center md:w-60% mx-auto mb-2 md:mb-10">
          {[0, 1].map((barIndex) => (
            <div
              key={barIndex}
              className={`rounded-[4px] px-4 flex-1 flex items-center justify-center cursor-pointer ${
                bar === barIndex ? "bg-black" : "bg-transparent"
              }`}
              onClick={() => setBar(barIndex)}
            >
              {bar === barIndex ? (
                <span className="text-gradient font-bold">
                  {
                    [
                      "Description",
                      "Specifications",
                      `Reviews (${data.reviews && data.reviews.length})`,
                    ][barIndex]
                  }
                </span>
              ) : (
                <span className="text-white font-bold">
                  {
                    [
                      "Description",
                      "Specifications",
                      `Reviews (${data.reviews && data.reviews.length})`,
                    ][barIndex]
                  }
                </span>
              )}
            </div>
          ))}
        </div>
        {bar === 0 ? (
          <div className="bg-bw-02 rounded-lg p-4 font-['cairo'] flex flex-col gap-2">
            <span className="text-white font-bold">Description</span>
            <div dangerouslySetInnerHTML={{ __html: data.description }} />
          </div>
        ) : bar === 1 ? (
          <div className="bg-bw-02 rounded-lg p-4 font-['cairo'] flex flex-col gap-2">
            <span className="text-white font-bold">Specifications</span>
            <table className="bg-black">
              {data.specifications &&
                data.specifications.map((ele, index) => {
                  return (
                    <tr key={index} className="text-white text-sm">
                      <td className="font-bold border-[1px] border-white p-2">
                        {ele[0]}
                      </td>
                      <td className="border-[1px] border-white p-2">
                        {ele[1]}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
        ) : (
          <div className="bg-bw-02 rounded-lg p-4 font-['cairo'] flex flex-col gap-2">
            <span className="text-white font-bold">
              Reviews ({data.reviews && data.reviews.length})
            </span>
            <div className="flex flex-col gap-2">
              {data.reviews &&
                data.reviews.map((ele) => (
                  <div
                    key={ele.id}
                    className="font-['cairo'] p-2 bg-black rounded-lg flex flex-col gap-2"
                  >
                    <div className="flex gap-2">
                      <img src={UserIcon} alt="icon" />
                      <div className="flex flex-col text-white">
                        <span className="text-lg font-bold">
                          {ele.username}
                        </span>
                        <span className="text-xs">{ele.created_at}</span>
                      </div>
                    </div>
                    <div className="flex gap-1">
                      {goldStars}
                      {grayStars}
                    </div>
                    <p className="text-white font-medium text-sm">
                      {ele.review_text}
                    </p>
                  </div>
                ))}
            </div>
          </div>
        )}
      </main>

      
      <BottomNav currentPage={""}></BottomNav>
      <Footer></Footer>
      <Cart></Cart>
      <ToastContainer></ToastContainer>
      <MobileMenu></MobileMenu>

      {isSearching ? (
        <>
          <SearchInput></SearchInput>
          <div onClick={() => setIsSearching(false)}>
            <Overlay></Overlay>
          </div>
        </>
      ) : null}

      {loading ? (
        <>
          <Overlay></Overlay> <Loader> </Loader>{" "}
        </>
      ) : null}
    </>
  );
}

export default ProductPage;
