import Header from "../components/Header";
import Nav from "../components/Nav";
import Loader from "../components/Loader";
import api from "../statics/api.js";

import Overlay from "../components/Overlay";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCookie } from "../App.js";
import Cart from "../components/cart.compontent.jsx";
import SearchInput from "../components/search-input.component.jsx";
import MobileMenu from "../components/menu.component.jsx";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import HeartGrey from "../assets/images/heart-grey.svg";
import ProductVertical from "../components/product-vertical.component.jsx";
import Footer from "../components/footer.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";

function WishListPage() {
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();
 

  const handleSearchClick = () => {
    setIsSearching((prev) => !prev);
  };
  const loadData = async () => {

    let postData = new FormData();
    const session = getCookie("session");

    if (session) {
        postData.append('session', session);
    }else {
      setIsLoggedIn(false)
        toast.error("You must login first.")
        return
    }

    setLoading(true);

    for (let i = 0; i < 100; i++) {
      try {
        let response = await fetch(`${api}get_wishlist.php`, {
            method: 'POST',
            body: postData
        });
        let responseData = await response.text();
        responseData = JSON.parse(responseData);
        console.log(responseData)
        if (responseData.state) {
            setLoading(false)
            setData(responseData["data"]);
        }else {
            return
        }
  
        return;
      } catch (error) {
        console.log(error)
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  
  useEffect(()=>{
    loadData()
  },[])
  return (
    <>
      <Nav></Nav>
      <Header onSearchClick={handleSearchClick}></Header>
      {
        data && isLoggedIn ?  <main className="m-6 min-h-[300px]">
        <h2 className="text-white font-semibold mb-6 ">WishList</h2>

        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 gap-2 ">
                            {
                                data && data.map((ele,index) => {
                                return <ProductVertical data={ele} index={index} inWishList={true}></ProductVertical>
                                })
                            }
                            </div>
        </main> : null
        
      }
      {
        isLoggedIn && data && data.length === 0 ? <main className="m-6 font-['cairo']">
            <h2 className="text-white font-semibold ">WishList</h2>
            <div className="flex flex-col w-full gap-6  items-center mt-10">
                <img src={HeartGrey} alt="img" className="w-[120px]"></img>
                <span className="text-white">Your wishlist is empty.</span>
                <button onClick={()=>navigate("/")} className="text-white w-[100%] h-12 max-w-80 font-bold bg-blue rounded-lg">Continue Shopping</button>
            </div>
        </main> : null
      }
    
    {
      !isLoggedIn && <main className="m-6 font-['cairo']">
            <h2 className="text-white font-semibold ">WishList</h2>
            <div className="flex flex-col w-full gap-6  items-center mt-10">
                <img src={HeartGrey} alt="img" className="w-[120px]"></img>
                <span className="text-white">You must login first to use wishlist</span>
                <button onClick={()=>navigate("/login")} className="text-white w-[100%] h-12 max-w-80 font-bold bg-blue rounded-lg">Login</button>
            </div>
        </main>
    }
      <Cart></Cart>
      <BottomNav currentPage={""}></BottomNav>
      <Footer></Footer>
      <ToastContainer></ToastContainer>
      <MobileMenu></MobileMenu>

      {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }

      {loading ? (
        <>
          <Overlay></Overlay> <Loader> </Loader>{" "}
        </>
      ) : null}
    </>
  );
}

export default WishListPage;
