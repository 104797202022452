import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../components/Header";
import Nav from "../components/Nav";
import Loader from "../components/Loader";
import api from "../statics/api.js";
import ProductVertical from "../components/product-vertical.component";


import Overlay from "../components/Overlay";

import SearchInput from "../components/search-input.component.jsx";
import Cart from "../components/cart.compontent.jsx";

import ReloadIcon from "../assets/icons/reload.svg"
import arrowLeftGray from "../assets/icons/arrow-left-gray.svg";
import arrowLeftColored from "../assets/icons/arrow-left-colored.svg";
import FilterIcon from "../assets/icons/filter.svg"
import arrowRightGray from "../assets/icons/arrow-right-gray.svg";
import arrowRightColored from "../assets/icons/arrow-right-colored.svg";

import BreadCrump from "../components/BreadCrump.component.jsx";
import MobileMenu from "../components/menu.component.jsx";
import Footer from "../components/footer.component.jsx";
import BottomNav from "../components/bottom-nav.component.jsx";
import { ToastContainer } from "react-toastify";
import { Slider } from "@mui/material";

function BrandProductsPage() {
    const { query } = useParams();

    const [isSearching, setIsSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState(undefined);
    const [mobileFilter, setMobileFilter] = useState(false);
    const [filters, setFilters] = useState([]);
    const [newFilters, setNewFilters] = useState([]);
    const [productsLength, setProductsLength] = useState(0);
    const [searchStart, setSearchStart] = useState(0);
  
 
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [value, setValue] = useState([0, 100]);
    const [max_price, setMaxValue] = useState(0);


    const Pagination = ({ numPages, currentPage }) => {
        const paginationElements = () => {
            if (numPages <= 9) {
                return Array.from({ length: numPages }, (_, i) => i + 1);
            } else if (currentPage >= 5) {
                return [
                    currentPage, currentPage + 1, currentPage + 2, currentPage + 3, 
                    '...',
                    numPages - 2, numPages - 1, numPages
                ];
            } else {
                return [
                    1, 2, 3, 4, 
                    '...',
                    numPages - 2, numPages - 1, numPages
                ];
            }
        };
    
        return (
            <>
                {paginationElements().map((page, index) => (
                    <div
                        key={index}
                        onClick={()=>{if (page !== "...") handlePageBtn(index+1)}}
                        className={`w-12  h-12 flex items-center justify-center text-white font-bold rounded-lg border-[1.5px]  ${page !== '...' ? 'cursor-pointer' : ''} ${currentPage === page ? 'border-gradient' : 'border-bw-03'}`}
                    >
                        {page}
                    </div>
                ))}
            </>
        );
    };


    const handlePageBtn =  (i) => {
        if (i > numPages || i <= 0) {

        }else {
            setCurrentPage(i)
            setSearchStart(i*20-20);
            handleSearchWithFilters(i*20-20)
            window.scrollTo(0,0)
        }

    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };

    const loadData = async () => {
        setLoading(true);
        for (let i = 0; i < 10; i++) {
            try {
                let response = await fetch(`${api}get_brand_products.php?brand_name=${query}&start=0&end=20&filters=[]`);
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (!responseData.state) {
                    setProducts([])
                    return
                }
                setProducts(responseData.products);
                setProductsLength(responseData.num_products);
                const groupedFilters = responseData.filters.reduce((acc, curr) => {
                    if (acc[curr.name]) {
                      acc[curr.name].values.push(curr.value);
                    } else {
                      acc[curr.name] = { name: curr.name, values: [curr.value] };
                    }
                    return acc;
                  }, {});  
                setFilters(Object.values(groupedFilters));
                setMaxValue(responseData.max_price);
                return;
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    const handleCheckBox = (parent, value) => {
        let newData = [...newFilters]; // Create a copy of newFilters array
        let is_found = false;
    
        // Check if the product already exists in newData
        newData.forEach((ele, index) => {
            if (ele.name === parent && ele.value === value) {
                is_found = true;
                // Remove the existing product from newData
                newData.splice(index, 1);
            }
        });
    
        if (!is_found) {
            // Add the new product if it doesn't already exist
            newData.push({ name: parent, value: value });
        }

        setNewFilters(newData);
    };

    const handleSearchWithFilters = async (start) => {
        setLoading(true);
        for (let i = 0; i < 10; i++) {
            try {
                const filters = JSON.stringify(newFilters);
                let response = await fetch(`${api}get_brand_products.php?brand_name=${query}&start=${start}&end=${start + 20}&filters=${filters}&max_price=${Math.round(( value[1] / 100 ) * max_price)}&min_price=${Math.round(( value[0] / 100 ) * max_price)}`);
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state === false) {
                    setProducts([]);
                    setProductsLength(0);
                    break;
                }
                setProducts(responseData.products);
                setProductsLength(responseData.products.length);
                const groupedFilters = responseData.filters.reduce((acc, curr) => {
                    if (acc[curr.name]) {
                      acc[curr.name].values.push(curr.value);
                    } else {
                      acc[curr.name] = { name: curr.name, values: [curr.value] };
                    }
                    return acc;
                  }, {});  
                setFilters(Object.values(groupedFilters));
                return;
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };



    const getNumPages = async () => {
        const x1 = productsLength / 20;
        // console.log(productsLength)
        const x2 = (x1 % 1 ) ? 1 : 0;
        setNumPages(Math.floor(x1) + x2);
        // console.log("x1: " + (x1));
    };
    
    function isFilterChecked  (parent, value) {
        const isChecked = newFilters.some(filter => filter.name === parent && filter.value === value);
        return isChecked;
    }

    const resetFilters = () => {
        setNewFilters([])
    }

    
    const ProductsSkeleton = () => {
        return <div className="flex flex-col gap-6">
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2 ">
            {
                Array.from({ length: 16 }).map((___, index)=> {
                    return <div
                    className={"min-w-[150px] animate-pulse  p-2 bg-bw-02 flex relative flex-col gap-2 rounded-lg flex-1 " }
                >
                    <div role="status" className="max-w-sm ">
                        <div className="h-48 bg-gray-200 rounded-lg mb-4"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[360px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[330px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[300px] mb-2.5"></div>
                        <div className="h-2 bg-gray-200 rounded-full  max-w-[100px] mb-2.5"></div>
                    </div>
                </div>
                }) }
                
            </div>
        </div>
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        console.log("Products Length: "  + productsLength)
        getNumPages();
    }, [productsLength]);

    return (
        <>
            <Nav />
            <Header onSearchClick={handleSearchClick} />
            <BreadCrump items={[{name: "home", to: "/"},{name: "brands", to: "/brands"},{name: query, to: `/brands/${query}`}]} ></BreadCrump>

            <main className="m-4 min-h-32">

                <div className="relative grid md:grid-cols-[288px,1fr] grid-cols-1 gap-4">
                    
                    {/* Filter */}
                    <div className="hidden md:flex mt-10 p-4 select-none left-4 z-20 rounded-lg bg-black w-[288px] border-solid border-[1.5px] border-bw-02 text-white font-['kensmark-03'] flex-col gap-4 h-fit">

                        <div className="flex justify-between items-center">
                            <span className="text-2xl">FILTER</span>
                            <img src={ReloadIcon} alt="icon" onClick={resetFilters} className="cursor-pointer"></img>
                        </div>
                        {
                            filters.map((parent,index) => {
                                return (
                                <div className="flex gap-2 flex-col" key={index}>
                                    <span>{parent.name}</span>
                                    <div className="bg-bw-02 h-[1.5px]"></div>
                                    <div className="flex gap-2 flex-col">
                                        {
                                            parent.values.map((child, idx) => {
                                                return <div className="flex gap-2 items-center" key={idx}>
                                                    <input className="cursor-pointer" type="checkbox" checked={isFilterChecked(parent.name, child)} onChange={() => handleCheckBox(parent.name, child)}></input>
                                                    <span className="font-['inter'] ">{child}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                        <div>
                            <span>PRICE</span>
                            <div>
                                <Slider
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                                <div className="flex justify-between">
                                    <span className="font-electrolize">LE { Math.round(( value[0] / 100 ) * max_price)} </span>
                                    <span className="font-['inter']">to</span>
                                    <span>LE { Math.round(( value[1] / 100 ) * max_price)}</span>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>handleSearchWithFilters(searchStart)} className="px-6 py-2 flex font-['inter'] font-bold justify-center items-center save-gradient rounded-md">Apply</button>

                    </div>


                    {/* Main Div  */}
                    <div className="mt-6 flex flex-col gap-6">
                        <div className="flex gap-2">
                            <img src={FilterIcon} alt="icon" className="relative z-50 cursor-pointer md:cursor-not-allowed" onClick={() => setMobileFilter(!mobileFilter)}></img>
                            <span className="text-white font-['cairo'] ">{query.toUpperCase()}: {productsLength} Products found</span>
                        </div>
                        {
                            products && products.length > 0  ?
                            <>
                                {/* Products */}
                                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 gap-2 ">
                                {
                                    products.map((ele, index) => (
                                        <ProductVertical data={ele} index={index} key={index}></ProductVertical>
                                    ))
                                }
                             
                                </div>
                                {/* Pages */}
                                <div className="flex gap-2 select-none items-center justify-center flex-wrap">
                                    <div onClick={()=>handlePageBtn(currentPage-1)} className="cursor-pointer   w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 ">
                                        <img src={arrowLeftGray} alt="icon"></img>
                                    </div>
                                    <Pagination numPages={numPages} currentPage={currentPage} />
                           
                                    <div onClick={()=>handlePageBtn(currentPage+1)} className=" cursor-pointer w-12 h-12 flex justify-center items-center rounded-lg border-[1.5px] border-bw-03 ">
                                        {
                                            currentPage !== numPages ? 
                                                <img src={arrowRightColored} alt="icon"></img>
                                            : 
                                                <img src={arrowRightGray} alt="icon"></img>
                                        }
                                    </div>
                                </div>
                            </> : null
                        }
                        {
                            !products && <ProductsSkeleton></ProductsSkeleton>                         
                        }
                        {
                            products && products.length === 0  && <div className="text-white">Sorry, No products found</div>
                        }
                    </div>
                </div>
     
            </main>

            {
                mobileFilter &&
                <div className="md:hidden">
                    <div onClick={()=>setMobileFilter(false)} className="cursor-pointer"><Overlay></Overlay></div>
                    <div className="absolute z-50 top-[218.5px] p-4 select-none left-4 rounded-lg bg-black w-[288px] border-solid border-[1.5px] border-bw-02 text-white font-['kensmark-03'] flex flex-col gap-4">
                        <div className="flex justify-between items-center">
                            <span className="text-2xl">FILTER</span>
                            <img src={ReloadIcon} alt="icon"></img>
                        </div>
                        {
                            filters.map((parent, index) => {
                                return (
                                <div className="flex gap-2 flex-col" key={index}>
                                    <span>{parent.name}</span>
                                    <div className="bg-bw-02 h-[1.5px]"></div>
                                    <div className="flex gap-2 flex-col">
                                        {
                                            parent.values.map((child, idx) => {
                                                return <div className="flex gap-2 items-center" key={idx}>
                                                {
                                                    <input className="cursor-pointer" type="checkbox" checked={isFilterChecked(parent.name, child)} onChange={() => handleCheckBox(parent.name, child)}></input>
                                                }
                                                    <span className="font-['inter'] ">{child}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                        <div>
                            <span>PRICE</span>
                            <div>
                                <Slider
                                    value={value}
                                    onChange={handleChange}
                                    valueLabelDisplay="auto"
                                />
                                <div className="flex justify-between">
                                    <span className="font-electrolize">LE { Math.round(( value[0] / 100 ) * max_price)} </span>
                                    <span className="font-['inter']">to</span>
                                    <span>LE { Math.round(( value[1] / 100 ) * max_price)}</span>
                                </div>
                            </div>
                        </div>
                        <button onClick={()=>handleSearchWithFilters(searchStart)} className="px-6 py-2 flex font-['inter'] font-bold justify-center items-center save-gradient rounded-md">Apply</button>
                    </div>
                </div>
            }

            {
                loading ? 
                <>
                <Loader></Loader>
                <Overlay></Overlay>
                </>
                : null
            }

            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }

            <Footer></Footer>
            <Cart></Cart>
            <MobileMenu></MobileMenu>
            <BottomNav currentPage={""}></BottomNav>

            <ToastContainer />
        </>
    );
}

export default BrandProductsPage;
