import { useState } from "react";

import Header from "../components/Header";
import Nav from "../components/Nav";
import Loader from "../components/Loader";
import api from "../statics/api.js"
import Overlay from "../components/Overlay";
import Cart from "../components/cart.compontent.jsx";
import FormInput from "../components/FormInput.jsx";
import EmailIcon from "../assets/icons/email-gray.svg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import OTPInput from "../components/otp-input.component.jsx";
import PasswordIcon from "../assets/icons/password-gray.svg";
import SearchInput from "../components/search-input.component.jsx";
import MobileMenu from "../components/menu.component.jsx";

// import arrowLeft from "../assets/icons/arrow-left-gray.svg";
// import arrowRight from "../assets/icons/arrow-left-gray.svg";


function RecoverpasswordPage() {

    const [isSearching, setIsSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [showOTP, setShowOTP] = useState(false)
    const [showPasswodChange, setShowPasswodChange] = useState(false);
    const [otp, setOTP] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };
    const handleRecoverPassword = async () => {
        if (email.trim().length === 0) {
            toast.error("Please write your email first.");
            return;
        }
        let postData = new FormData();
        postData.append('email', email);
        setLoading(true);
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}recover_password.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                    toast.success(responseData.msg);
                    setShowOTP(true)
                }else {
                    toast.error(responseData.msg)
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        }
    }
    const handleValidateOTP = async () => {
        if (otp.trim().length < 6) {
            toast.error("Please write the otp first.");
            return;
        }
        let postData = new FormData();
        postData.append('email', email);
        postData.append('otp', otp);
        setLoading(true);
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}authorize_otp.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                    toast.success(responseData.msg);
                    setShowOTP(false);
                    setShowPasswodChange(true)
                }else {
                    toast.error(responseData.msg)
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        }
    }
    const handleChangePassword = async () => {
        if (password.trim().length < 8) {
            toast.error("Please enter a password that is at least 8 characters long.");
            return;
        }
        let postData = new FormData();
        postData.append('email', email);
        postData.append('otp', otp);
        postData.append('new_password', password);
        setLoading(true);
        for (let i = 0; i < 100; i++) {
            try {
                let response = await fetch(`${api}change_password.php`, {
                    method: 'POST',
                    body: postData
                });
                let responseData = await response.text();
                responseData = JSON.parse(responseData);
                setLoading(false);
                if (responseData.state) {
                    toast.success(responseData.msg);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    navigate("/login")
                }else {
                    toast.error(responseData.msg)
                }
                return
            } catch (error) {
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        }
    }

    return (
        <>
            <Nav />
            <Header onSearchClick={handleSearchClick} />
            {
                !showOTP && !showPasswodChange &&  <main className="mt-6 bg-bw-02  md:max-w-[40%] md:mx-auto rounded-lg mx-4 p-2 flex flex-col gap-2 font-bold font-['Montserrat'] md:gap-3 md:p-3">
                <h1 className="text-white">Recover your Password</h1>
                <FormInput placeHolder="Write your email address here ..." img={EmailIcon} type={"email"}  name="Email Address" onChange={(ele)=>setEmail(ele.target.value)}></FormInput>
                <button className="bg-blue h-12 rounded-lg font-['cairo'] font-bold text-white text-xl " onClick={handleRecoverPassword}>Recover Password</button>
                <div className="bg-white w-3/4 h-px mx-auto my-2"></div>
                <div className="block mx-auto font-bold text-white text-xs" href="" >Don't have and account? <Link to="/signup"><span className="font-bold text-blue cursor-pointer">Sign Up</span></Link> </div>
            </main>
            }
            {
                showOTP && 
                <main className="mt-6 bg-bw-02  md:max-w-[450px] md:mx-auto rounded-lg mx-4 p-2 flex flex-col gap-2 font-bold font-['Montserrat'] md:gap-3 md:p-3">
                    <h1 className="text-white">Enter OTP Code</h1>
                    <h3 className="mx-auto text-blue text-xs">Enter OTP Code</h3>
                    <div className="flex justify-center">
                        <OTPInput
                            length={6}
                            onChange={(value) => setOTP(value)}
                        />
                    </div>
                    <button className="bg-blue h-12 rounded-lg font-['cairo'] font-bold text-white text-xl " onClick={handleValidateOTP}>Recover Password</button>
                    <div className="bg-white w-3/4 h-px mx-auto my-2"></div>
                    <div className="block mx-auto font-bold text-white text-xs" href="" >Don't have and account? <Link to="/signup"><span className="font-bold text-blue cursor-pointer">Sign Up</span></Link> </div>
                </main>
            }
            {
                showPasswodChange && 
                <main className="mt-6 bg-bw-02  md:max-w-[450px] md:mx-auto rounded-lg mx-4 p-2 flex flex-col gap-2 font-bold font-['Montserrat'] md:gap-3 md:p-3">
                    <h1 className="text-white">Enter New Password</h1>
                    <FormInput placeHolder="Write your password here ..." img={PasswordIcon} type={"password"}  name="Password" onChange={(ele)=>setPassword(ele.target.value)}></FormInput>
                    <button className="bg-blue h-12 rounded-lg font-['cairo'] font-bold text-white text-xl " onClick={handleChangePassword}>Done</button>
                    <div className="bg-white w-3/4 h-px mx-auto my-2"></div>
                    <div className="block mx-auto font-bold text-white text-xs" href="" >Don't have and account? <Link to="/signup"><span className="font-bold text-blue cursor-pointer">Sign Up</span></Link> </div>
                </main>
            }
           
            {
                loading && <Overlay></Overlay>
            }

            <MobileMenu></MobileMenu>

            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
            <ToastContainer></ToastContainer>
            <Cart></Cart>
        </>
    );
}

export default RecoverpasswordPage;
