import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set_username } from "./reducers/username";
import LoginPage from "./pages/Login";
import HomePage from "./pages/Home";
import SignupPage from "./pages/Signup";
import SearchPage from "./pages/Search";
import api from "./statics/api"

import { setPopularProducts, setFeaturedSavings , setTodayBestDeals } from "./reducers/home";
import { set_cart } from "./reducers/cart";
import ProductPage from "./pages/Product";
import CheckoutPage from "./pages/Checkout";
import FlashSales from "./pages/FlashSales";
import WishListPage from "./pages/WishList";
import RecoverpasswordPage from "./pages/RecoverPassword";
import CategoryPage from "./pages/Category";
import TodayBestDeals from "./pages/TodayBestDeals";
import FeaturedSavings from "./pages/FeaturedProducts";
import PopularProducts from "./pages/PopularProducts";
import BuildYourPCPage from "./pages/BuildYourPC";
import BrandsPage from "./pages/Brands";
import BrandProductsPage from "./pages/BrandProducts";
import CategoryProductsPage from "./pages/Category";
import CategoriesPage from "./pages/Categories";
import MyAccount from "./pages/MyAccount";
import MyOrders from "./pages/MyOrders";

export const  getCookie = (cookieName) => {
  var cookies = document.cookie.split(';');

  for(var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if(cookie.startsWith(cookieName + '=')) {
          return cookie.substring(cookieName.length + 1);
      }
  }
  // If cookie not found, return null
  return false;
}
export default function App() {
  const dispatch = useDispatch();
  const handleRequest = async () => {
    let postData = new FormData();
    const session = getCookie("session");
    let cart = localStorage.getItem("cart") || "[]";

    if (session) {
        postData.append('session', session);
    }else {
    
      if (cart) {
        set_cart(dispatch(set_cart(JSON.parse(cart))))
        postData.append("cart",cart)
      }
    }

    for (let i = 0; i < 100; i++) {
        try {
        
            let response = await fetch(`${api}first_load.php`, {
                method: 'POST',
                body: postData
            });
            let responseData = await response.text();
            responseData = JSON.parse(responseData);
            if (responseData.cart) {
              set_cart(dispatch(set_cart(responseData["cart"])));
              localStorage.setItem("cart", JSON.stringify(responseData["cart"])) ;
            }
            dispatch(setTodayBestDeals(responseData["today_best_deals"]));
            dispatch(setFeaturedSavings(responseData["featured_savings"]));
            dispatch(setPopularProducts(responseData["popular_products"]));
            if (session ) {
              set_cart(dispatch(set_cart(responseData["cart"])))

              if (responseData.res.state ) {
                if (responseData.res.msg  === "New session") {
                    const expiryDate = new Date(responseData.exp);
                    document.cookie = `session=${responseData.new_session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;                    
                    localStorage.setItem("fullname",responseData.res.username);
                    dispatch(set_username(localStorage.getItem("fullname")));
                }
                dispatch(set_cart(responseData.cart))
                return
            }else {
                // Delete Session
                // toast.error("Please login again.")
                await new Promise(resolve => setTimeout(resolve, 2000));
                document.cookie = `session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=Strict`;
                localStorage.setItem("cart", "[]")
                dispatch(set_cart([]))
                window.location.reload();
            }
            }
          
            
            return
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
}
  useEffect(() => {
    const cookieExists = document.cookie.split("; ").some((row) => row.startsWith("session="));
    if (cookieExists) {
      dispatch(set_username(localStorage.getItem("fullname")));
    } else {
    }
    handleRequest();
  }, []);

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/search/:query" element={<SearchPage />} />
          <Route path="/product/" element={<ProductPage />} />
          <Route path="/product/:query" element={<ProductPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/sales" element={<FlashSales />} />
          <Route path="/wishlist" element={<WishListPage />} />
          <Route path="/category/*" element={<CategoryProductsPage />} />

          {/* <Route path="/category/:" element={<CategoryProductsPage></CategoryProductsPage>} /> */}
          <Route path="/recover-password" element={<RecoverpasswordPage />} />
          <Route path="/today-best-deals" element={<TodayBestDeals />} />
          <Route path="/featured-savings" element={<FeaturedSavings />} />
          <Route path="/popular-products" element={<PopularProducts />} />
          <Route path="/build-your-pc" element={<BuildYourPCPage />} />
          <Route path="/brands" element={<BrandsPage />} />
          <Route path="/brands/:query" element={<BrandProductsPage />} />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/my-account/orders" element={<MyOrders />} />
          
        </Routes>
      
      </BrowserRouter>
  );
}