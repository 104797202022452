import Nav from "../components/Nav";
import SearchInput from "../components/search-input.component";
import Overlay from "../components/Overlay";
import MobileMenu from "../components/menu.component";
import BottomNav from "../components/bottom-nav.component";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/footer.component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import CartIcon from "../assets/icons/cart.svg";
import BreadCrump from "../components/BreadCrump.component";
import Profile from "../assets/icons/profile.svg";
import Logout from "../assets/icons/logout.svg";

import Wishlist from "../assets/icons/heart.svg";
import Cart from "../components/cart.compontent";


function MyOrders () {
    const [isSearching, setIsSearching] = useState(false);
    const navigate = useNavigate();

    const breadcrumbItems = [
        { name: "HOME", slug: "/" },
        { name: "My Account", slug: "/my-account"},
        { name: "My Orders", slug: "/my-account/orders" },
      ];

    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
      };

    return (
        <>
        <Helmet>
            <title>My Orders</title>
        </Helmet>

        <Nav />
        <Header onSearchClick={handleSearchClick} />
        <BreadCrump items={breadcrumbItems} />



        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Product name
                </th>
                <th scope="col" className="px-6 py-3">
                    Color
                </th>
                <th scope="col" className="px-6 py-3">
                    Category
                </th>
                <th scope="col" className="px-6 py-3">
                    Price
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Apple MacBook Pro 17"
                </th>
                <td className="px-6 py-4">
                    Silver
                </td>
                <td className="px-6 py-4">
                    Laptop
                </td>
                <td className="px-6 py-4">
                    $2999
                </td>
                <td className="px-6 py-4 text-right">
                    <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Microsoft Surface Pro
                </th>
                <td className="px-6 py-4">
                    White
                </td>
                <td className="px-6 py-4">
                    Laptop PC
                </td>
                <td className="px-6 py-4">
                    $1999
                </td>
                <td className="px-6 py-4 text-right">
                    <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
            <tr className="bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Magic Mouse 2
                </th>
                <td className="px-6 py-4">
                    Black
                </td>
                <td className="px-6 py-4">
                    Accessories
                </td>
                <td className="px-6 py-4">
                    $99
                </td>
                <td className="px-6 py-4 text-right">
                    <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                </td>
            </tr>
        </tbody>
    </table>
</div>


        <Cart></Cart>
      <Footer />
      <MobileMenu />
      <BottomNav currentPage="" />

        </>
    )
}

export default MyOrders