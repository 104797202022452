import { Link } from "react-router-dom";
import HomeWhite from "../assets/icons/bottomNav/home.svg";
import HomeGradient from "../assets/icons/bottomNav/home-gradient.svg";
import CategoriesGradient from "../assets/icons/bottomNav/category-gradient.svg";
import CartGradient from "../assets/icons/bottomNav/cart-gradient.svg";
import CategoryWhite from "../assets/icons/bottomNav/category.svg";
import CartWhite from "../assets/icons/bottomNav/cart.svg";
import ProfileWhite from "../assets/icons/bottomNav/profile.svg";
import ProfileGradient from "../assets/icons/bottomNav/profile-gradient.svg";
import { useDispatch, useSelector } from "react-redux";
import { set_cart, set_cart_state } from "../reducers/cart";
import { useEffect } from "react";

function BottomNav({ currentPage }) {
  // Define an array of navigation items
  const navItems = [
    { icon: HomeWhite, activeIcon: HomeGradient, label: "Home", page: "Home", route: "/" },
    { icon: CategoryWhite, label: "Category", page: "Category", route: "/categories" },
    { icon: CartWhite, label: "Cart", page: "Cart", route: "/cart" },
    { icon: ProfileWhite, label: "Profile", page: "Profile", route: "/profile" },
  ];
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  useEffect(()=>{
  })
  return (
    <div className="md:hidden z-[100] fixed left-0 right-0 bottom-0 bg-bw-02  flex justify-between items-center px-6 py-3">
      {/* {navItems.map((item, index) => (
        <Link key={index} to={item.route} className={currentPage === item.page ? "px-3 py-1 gap-2 flex bg-black rounded-full items-center" : ""}>
          <img src={currentPage === item.page ? item.activeIcon : item.icon} alt="ico" />
          {currentPage === item.page ? <span className="text-gradient font-['kensmark-03']">{item.label}</span> : null}
        </Link>
      ))} */}
      <Link key={0} to={"/"} className={currentPage === "Home" ? "px-3 py-1 gap-2 flex bg-black rounded-full items-center" : ""}>
          <img src={currentPage === "/" ?  HomeGradient : HomeWhite} alt="ico" />
          {currentPage === "/" ? <span className="text-gradient font-['kensmark-03']">{"Home"}</span> : null}
      </Link>
      <Link key={1} to={"/categories"} className={currentPage === "/categories" ? "px-3 py-1 gap-2 flex bg-black rounded-full items-center" : ""}>
          <img src={currentPage === "/categories" ?  CategoriesGradient : CategoryWhite} alt="ico" />
          {currentPage === "/categories" ? <span className="text-gradient font-['kensmark-03']">{"Categories"}</span> : null}
      </Link>
      <div key={2} className={cart.state  ? "px-3 py-1 gap-2 flex bg-black rounded-full items-center cursor-pointer " : "cursor-pointer flex items-center"} onClick={()=>set_cart(dispatch(set_cart_state(!cart.state)))}>
          <img src={cart.state ?  CartGradient : CartWhite} alt="ico" />
          {cart.state ? <span className="text-gradient font-['kensmark-03']">{"Cart"}</span> : null}
          <div className="flex text-white p-2 justify-center items-center  save-gradient rounded-full w-4 h-4 ">{cart.items.length}</div>

      </div> 
      <Link key={3} to={"/profile"} className={currentPage === "/profile" ? "px-3 py-1 gap-2 flex bg-black rounded-full items-center" : ""}>
          <img src={currentPage === "/profile" ?  ProfileGradient : ProfileWhite} alt="ico" />
          {currentPage === "/profile" ? <span className="text-gradient font-['kensmark-03']">{"Profile"}</span> : null}
      </Link>
    </div>
  );
}

export default BottomNav;
