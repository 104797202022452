import { useEffect, useState } from "react";


// if img.props is null so the element is used in the checkout so give the title color wite
function FormInput(props) {
    // Add gradient border on focus on input
    const [border, setBorderState] = useState(false);

    const handleFocus = () => {
        setBorderState(true);
    }
    const handleBlur = () => {
        setBorderState(false);
    }

    const handleChange = (event) => {
        if (props.onChange) {
            props.onChange(event);
        }
    }

    return (
        <>
            
        {props.img ? 
            <span className={`text-xs  font-['cairo'] font-bold ${border ? "text-blue" : "text-bw-04"} transition duration-100`}>{props.name}</span>
         : 
         <span className={`text-xs  font-['cairo']  ${border ? "text-blue" : "text-white"} transition duration-100`}>{props.name}</span>

         }

        <div className={`${border ? "border-gradient" : "border-none"}`}>
            <div className={` border-solid border-[1.5px] border-bw-03  rounded-[8px]  ${border ? "border-[0px]" : ""}  flex align-center gap-[8px] px-[12px] h-[48px] bg-black `}>
                {props.img && <img className="w-[24px] fill-bw-04" src={props.img} alt="profile_icon" />}
                <input
                type= {props.type}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={props.placeHolder}
                    className="myinput bg-transparent border-none outline-none flex-1 font-['cairo'] text-xs caret-white text-white placeholder:text-bw-04"
                    onChange={handleChange}
                />
            </div>
        </div>
        </>

    );
}

export default FormInput;
