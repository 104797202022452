import React from "react";
import { useNavigate } from "react-router-dom";

function CategoryCard(props) {
  console.log(props)

  // Split the name into words and join them with line breaks
  const nameWithLineBreaks = props.data.name;
  const navigate = useNavigate();
  
  // Handle navigation with the correct route path
  const handleNavigation = () => {
    const path = `/category/${props.data.slug}`;
    navigate(path);
  };

  return (
    <div 
      className="bg-bw-02 cursor-pointer px-2 py-4 gap-4 flex flex-col items-center rounded-lg min-w-[180px] justify-center"
      onClick={handleNavigation}
    >
      <img src={props.data.src} alt={props.data.name} className="min-h-6" /> 
      {/* Apply white-space: pre-line to preserve line breaks */}
      <span className="text-white text-xl sfont-['kensmark-03'] whitespace-pre-line text-center font-[kensmark-03]">
        {nameWithLineBreaks}
      </span>
    </div>
  );
}

export default CategoryCard;
