import FormInput from "../components/FormInput.jsx";
import Header from "../components/Header.jsx";
import Nav from "../components/Nav.jsx";
import Profile from "../assets/icons/profile-gray.svg";
import Email from "../assets/icons/email-gray.svg";
import Password from "../assets/icons/password-gray.svg";
import { useEffect, useState } from "react";
import CheckBox from "../components/CheckBox.jsx";
import { set_username } from "../reducers/username";
import  api from "../statics/api.js"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../components/Loader.jsx";
import Ovarlay from "../components/Overlay.jsx";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SearchInput from "../components/search-input.component.jsx";
import Overlay from "../components/Overlay.jsx";
import Cart from "../components/cart.compontent.jsx";
import MobileMenu from "../components/menu.component.jsx";

function SignupPage() {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false )
    const [isSearching, setIsSearching] = useState(false);

    const showError = (msg) => toast.warning(msg,{theme: "dark"});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = (type, value) => {
        setFormData({...formData, [type]: value});
    }

    const handleSignUp = async (e) => {
        e.preventDefault(); // Prevent form submission and page reload
        const { fullname, email, password, "retype-password": retypePassword , checkbox } = formData;
    
        // Regular expressions for email and username validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const usernameRegex = /^[a-zA-Z0-9_\-]{2,50}$/;
    
        // Validation for username and password
        if (!fullname || !usernameRegex.test(fullname)) {
            showError("Username should be between 2 and 50 characters and contain only letters, numbers, underscores, and hyphens.");
            return;
        }
        if (!email || !emailRegex.test(email)) {
            showError("Please enter a valid email address.");
            return;
        }
        if (!password || password.length < 8) {
            showError("Password should be at least 8 characters long.");
            return;
        }
        if (password !== retypePassword) {
            showError("Passwords do not match.");
            return;
        }
        if (!checkbox) {
            showError("You must agree terms and conditions.");
            return;
        }
        setLoading(true);
    
        let postData = new FormData();
        postData.append('username', fullname);
        postData.append('pwd', password);
        postData.append('email', email);

        const handleRequest = async () => {
            for (let i = 0; i < 100; i++) {
                try {
                    let response = await fetch(`${api}signup.php`, {
                        method: 'POST',
                        body: postData
                    });
                    let responseData = await response.text();
                    responseData = JSON.parse(responseData);
                        setLoading(false);

                    // Signup successful
                    if (responseData.state) {
                        const expiryDate = new Date(responseData.exp);
                        document.cookie = `session=${responseData.session}; expires=${expiryDate.toUTCString()}; Secure; SameSite=Strict`;
                        localStorage.setItem("fullname",fullname);
                        dispatch(set_username(localStorage.getItem("fullname")));
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        navigate("/")
                        return;
                    }
                    // Error
                    else {
                        showError(responseData.msg)
                        return                    
                    }
                } catch (error) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            }
            throw new Error('Sign up failed. Please try again.'); // Max retry attempts reached
        }
        handleRequest();
        
 
        
    }
    
    const handleSearchClick = () => {
        setIsSearching((prev) => !prev);
    };
    return (
        <>
            <Nav></Nav>
            <Header onSearchClick={handleSearchClick}></Header>


            <form action="" className="mt-6 w-[95%] mx-auto px-2 py-2 rounded-2xl bg-bw-02 flex flex-col gap-2" method="">
                <FormInput placeHolder="Write your full name here ..." img={Profile} type={"text"} name="Full Name" onChange={(ele)=>handleInputChange("fullname",ele.target.value)}></FormInput>
                <FormInput placeHolder="Write your email address here ..." img={Email} type={"email"}  name="Email Address" onChange={(ele)=>handleInputChange("email",ele.target.value)}></FormInput>
                <FormInput placeHolder="Write your password here ..." img={Password} type={"password"}  name="Password" onChange={(ele)=>handleInputChange("password",ele.target.value)}></FormInput>
                <FormInput placeHolder="Write your password here ..." img={Password} type={"password"} name="Retype Password" onChange={(ele)=>handleInputChange("retype-password",ele.target.value)}></FormInput>
                <CheckBox onChange={(ele)=>handleInputChange("checkbox",ele.target.checked)}></CheckBox>
                <button className="bg-blue flex align-center justify-center font-['cairo'] px-4 py-3 font-bold text-xl text-white rounded-xl" onClick={handleSignUp}>
                    Sign Up
                </button>
            </form>
            {
                loading ? 
                <>
                <Loader></Loader>
                <Ovarlay></Ovarlay>
                </>

                : null
            }
            {
                isSearching ? 
                <>
                <SearchInput></SearchInput>
                <div onClick={()=>setIsSearching(false)}>
                    <Overlay></Overlay>
                </div>
                </> : null
            }
           <ToastContainer></ToastContainer>
           <Cart></Cart>
           <MobileMenu></MobileMenu>
            <ToastContainer />
        </>   
    )
}

export default SignupPage;
